import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../UserContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { startAuthentication } from '@simplewebauthn/browser';

const Telegram = () => {
    const [startApp, setStartApp] = useState('');    
    const { login } = useUser();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Expand the window to fit the screen
        const tgWebApp = window.Telegram?.WebApp;
        if (tgWebApp) {
            tgWebApp.expand();
        }

        // Get Telegram Web App initialization data
        const initDataString = tgWebApp?.initData || '';

        handleLogin(initDataString);

    }, [location]);

    const handleLogin = async (initDataString) => {
        // Parse and log the initData in more detail
        try {
            const searchParams = new URLSearchParams(initDataString);        
            const token = await telegramLogin(initDataString);
            const startAppParam = searchParams.get('start_param');
            
            if (startAppParam) {
                setStartApp(startAppParam);        
                setTimeout(() => {
                    decodeLink(token, startAppParam);
                }, 5_000);
            } else {
                setTimeout(() => {
                    navigate('/dashboard');
                }, 1_000);
            }


        } catch (error) {
            console.error('Error parsing initData:', error);
        }        
    }

    const telegramLogin = async (initDataString) => {
        try {
            const response = await axios.post('https://tot-api.mydreamstudio.workers.dev/auth/telegram', { initData: initDataString });
            const token = response.data;
            login(response.data);
            console.log(`token: ${token}`)            
            return token;
        } catch (error) {
            console.error('Error logging in with Telegram:', error);
        }
    }

    const decodeLink = async (token, link) => {
        try {
            const response = await axios.get(`https://tot-api.mydreamstudio.workers.dev/links/${link}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log('response from decodeLink', response.data);

            const gardenId = response.data.garden.id;
            navigate(`/gardens/${gardenId}`);

        } catch (error) {
            console.error('Error decoding link:', error);
            setTimeout(() => {
                navigate('/market');
            }, 1_000);
        }
    }

    return (
        <div className="min-h-screen bg-cover bg-center flex flex-col items-center justify-center" style={{ backgroundImage: `url(https://tot-app.pages.dev/images/background.jpg)` }}>

        </div>
    );
};

export default Telegram;