import React, { useState } from 'react';  
import { Link } from 'react-router-dom';


const ResourceBar = () => {
  
  const [gold, setGold] = useState(0);
  const [silver, setSilver] = useState(0);
  const [aqua, setAqua] = useState(0);
  const [sun, setSun] = useState(0);

  return (
    <div className="fixed top-0 left-0 right-0 bg-[#f4ebcb] p-2 shadow-md z-10">
      <Link to="/swap">
        <div className="container mx-auto">
          <ul className="flex justify-between items-center">
            <ResourceItem icon="gold-icon.png" value={gold} />
            <ResourceItem icon="silver-icon.png" value={silver} />
            <ResourceItem icon="water-icon.png" value={aqua} />
            <ResourceItem icon="sun-icon.png" value={sun} />
          </ul>
        </div>
      </Link>
    </div>
  );
};

const ResourceItem = ({ icon, value }) => (
  <li className="flex-1">
    <div className="flex flex-row items-center justify-center">
    <span className="whitespace-nowrap text-xs">{value}</span>
      <img src={`/images/${icon}`} alt={`${icon} Icon`} className="w-4 h-4 ml-1 flex-shrink-0" />
      
    </div>
  </li>
);

export default ResourceBar;
