import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../UserContext';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';

const TwitterLogin = () => {

    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        handleLogin();
    }, [location]);

    const handleLogin = async () => {

        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');
        if (token) {
            console.log(token);
            toast.success(`Connecting to Twitter...`, {
                position: "bottom-center"
            });
        } else {
            toast.error(`No token received from the server`, {
                position: "bottom-center"
            });
        }

        const response = await axios.get('https://tot-api.mydreamstudio.workers.dev/twitter/login', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log(response.data);
        if (response.data) {
            localStorage.setItem("userId", response.data.userId);
            localStorage.setItem("token", token);
            toast.success('Received Twitter authorization URL', {
                position: "bottom-center"
            });            
            setTimeout(() => {
                window.location.href = response.data.url;
            }, 3000);
        } else {
            toast.error('Failed to get Twitter authorization URL', {
                position: "bottom-center"
            });
            setTimeout(() => {
                window.close();
            }, 3000);
        }

    };



    return (
        <div className="min-h-screen bg-cover bg-center flex flex-col items-center justify-center" style={{ backgroundImage: `url(https://bubble-garden-app.pages.dev/images/background.jpg)` }}>

        </div>
    );
};

export default TwitterLogin;