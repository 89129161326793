import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserProvider } from './UserContext';
import { TonConnectUIProvider } from '@tonconnect/ui-react';

import Dashboard from './pages/Dashboard';
import Telegram from './pages/Telegram';
// Add imports for new pages

import UserDetail from './pages/UserDetail';
import TwitterLogin from './pages/TwitterLogin';
import TwitterCallback from './pages/TwitterCallback';


import ResourceBar from './components/ResourceBar';
import BottomNavBar from './components/BottomNavBar';



const App = () => {

  

  useEffect(() => {

    const tgWebApp = window.Telegram?.WebApp;
    if (tgWebApp) {
      tgWebApp.expand();
    }

    // Set up the interval and store the interval ID
    // const intervalId = setInterval(updateUser, 60000);

    // // Cleanup function
    // return () => {
    //   // Clear the interval when the component unmounts
    //   clearInterval(intervalId);
    // };

  }, []);

  const authenticateUser = () => {
    const token = localStorage.getItem('token');
    if (token) { return true; } else { return false; }
  };

  const updateUser = async () => {
    const token = localStorage.getItem('token');
    if (token) { await fetchUserData(token); } else { return false; }
  }

  const fetchUserData = async (token) => {
    console.log(`fetchUserData: ${token}`);
  }

  const PrivateRoute = ({ children }) => {
    //const {milliSeed, milliGold, milliSun, milliAqua} = getMilliResources()
    //console.log(`milliSeed: ${milliSeed}, milliGold: ${milliGold}, milliSun: ${milliSun}, milliAqua: ${milliAqua}`);
    return authenticateUser() ? (
      <>

        <ResourceBar />

        <div className="mt-8">
          {/* This div creates space between the top navigation and the main content */}
          {/* The mt-16 class adds margin to the top, pushing content below the fixed top nav */}
          {/* The mb-20 class adds margin to the bottom, creating space above the fixed bottom nav */}
        </div>

        {children}

        <ToastContainer />


        <div className="mb-20">
          {/* This div creates space between the top navigation and the main content */}
          {/* The mt-16 class adds margin to the top, pushing content below the fixed top nav */}
          {/* The mb-20 class adds margin to the bottom, creating space above the fixed bottom nav */}
        </div>

        <BottomNavBar />
        
      </>
    ) : <Navigate to="/tma" />;
  };
  return (
    <TonConnectUIProvider manifestUrl="https://bubble-garden-app.pages.dev/tonconnect-manifest.json">
      <UserProvider>
        <Router>
          <div className="min-h-screen bg-background text-foreground" >
            <Routes>
              <Route path="/" element={authenticateUser() ? <Navigate to="/dashboard" /> : <Telegram />} />
              <Route path="/tma" element={<Telegram />} />
              <Route path="/twitter/login" element={<TwitterLogin />} />
              <Route path="/twitter/callback" element={<TwitterCallback />} />

              {/* Wrap authenticated routes with PrivateRoute */}
              <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />                                                  
              <Route path="/users/:id" element={<PrivateRoute><UserDetail /></PrivateRoute>} />

            </Routes>
          </div>
        </Router>
      </UserProvider>
    </TonConnectUIProvider>

  );
};

export default App;
