import React from 'react';
import { Link } from 'react-router-dom';

const navItems = [
  { to: "/market", icon: "market-icon.png", label: "Mart" },
  { to: "/garden", icon: "garden-icon.png", label: "Farm" },
  // { to: "/planter", icon: "tool-icon.png", label: "Plant" },
  // { to: "/oracle", icon: "oracle-icon.png", label: "Sage" },
  { to: "/treasure", icon: "tool-icon.png", label: "Task" },
  { to: "/profile", icon: "farmer-icon.png", label: "User" },
];

const NavItem = ({ to, icon, label }) => (
  <li className="flex-1">
    <Link to={to} className="flex flex-col items-center justify-center text-green-800 hover:text-green-400 transition-colors">
      <img src={`/images/${icon}`} alt={`${label} Icon`} className="w-6 h-6 mb-1 flex-shrink-0" />
      <span className="whitespace-nowrap text-xs">{label}</span>
    </Link>
  </li>
);

const BottomNavBar = () => {
  return (
    <nav className="fixed bottom-0 left-0 right-0 bg-[#f4ebcb] p-4 shadow-md z-10">
      <div className="container mx-auto">
        <ul className="flex justify-between items-center">
          {navItems.map((item, index) => (
            <NavItem key={index} {...item} />
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default BottomNavBar;
