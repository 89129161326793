import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../UserContext';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';

const TwitterCallback = () => {
    const [isConnected, setIsConnected] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        handleCallback();
    }, [location]);

    const handleCallback = async () => {
        const searchParams = new URLSearchParams(location.search);
        const token = localStorage.getItem('token');
        const code = searchParams.get('code');
        const state = searchParams.get('state');

        if (!token || !code || !state) {
            toast.error(`No token, code, or state received from the server`, {
                position: "bottom-center"
            });
            return;
        }

        const response = await axios.get(`https://tot-api.mydreamstudio.workers.dev/twitter/callback?state=${state}&code=${code}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        console.log(response.data);
        if (response.data) {
            setIsConnected(true);
            toast.success('Bubble Garden is connected to your Twitter account', {
                position: "bottom-center"
            });
        } else {
            toast.error('Failed to get Twitter authorization URL', {
                position: "bottom-center"
            });
        }
    };


    return (
        <div className="min-h-screen bg-cover bg-center flex flex-col items-center justify-center" style={{ backgroundImage: `url(https://tot-app.pages.dev/images/background.jpg)` }}>
            {isConnected ? (
                <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                    <h2 className="text-2xl font-bold mb-4">Connection Successful!</h2>
                    <p className="mb-4">Bubble Garden is now connected to your Twitter account.</p>
                    <p className="mb-4">You can close this window and return to the app.</p>
                </div>
            ) : (
                <div className="text-white text-2xl">Connecting to Twitter...</div>
            )}
        </div>
    );
};

export default TwitterCallback;