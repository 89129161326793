import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useUser, useTelegramUsername } from "../UserContext";

const Dashboard = () => {
  const { token } = useUser();
  const { telegramUsername, setTelegramUsername } = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [plants, setPlants] = useState([{
    id: 1,
    name: "Seed",
    description: "Seed",
    image: "https://placehold.co/640x360",
  },
  {
    id: 2,
    name: "Sprout",
    description: "Sprout",
    image: "https://placehold.co/640x360",
  }]);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      fetchDashboardData();
      //fetchPlants();
    }
  }, [token, navigate]);

  const fetchDashboardData = async () => {
    try {
      const response = await axios.get(
        "https://tot-api.mydreamstudio.workers.dev/me/dashboard",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      //setTelegramUsername(response.data.telegramUsername);
    } catch (err) {
      setError("Failed to fetch dashboard data");
      console.error(err);
    }
  };

  const fetchPlants = async () => {
    try {
      const response = await axios.get(
        "https://tot-api.mydreamstudio.workers.dev/me/plants",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPlants(response.data);
    } catch (err) {
      setError("Failed to fetch plants data");
      console.error(err);
    }
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="min-h-screen p-4" style={{ backgroundImage: `url(https://tot-app.pages.dev/images/background-all.jpg)` }}>            
      
        {/* First section: Full-scale image */}
        <div className="mb-6">
          <img
            src="https://placehold.co/640x360"
            alt="Garden Overview"
            className="w-full h-auto rounded-lg shadow-md"
          />
        </div>

        {/* Second section: Button group */}
        <div className="flex space-x-4 mb-6">
          {['seed', 'sprout', 'king', 'dead'].map((status) => (
            <button
              key={status}
              className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition-colors shadow-md capitalize"
            >
              {status}
            </button>
          ))}
        </div>

        {/* Third section: List of plants */}
        <div className="space-y-4">
          {plants.map((plant) => (
            <div key={plant.id} className="flex items-center bg-white p-4 rounded-lg shadow-md">
              <div className="flex-grow">
                <h3 className="text-lg font-semibold text-green-800">{plant.name}</h3>
                <p className="text-green-600">{plant.description}</p>
              </div>
              <img src={plant.image} alt={plant.name} className="w-24 h-24 rounded-lg ml-4" />
            </div>
          ))}
        </div>

        {/* ... existing code for other sections ... */}
      
    </div>
  );
};

export default Dashboard;